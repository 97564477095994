<template>
  <div id="app">
  <v-app id="inspire">
    <v-row>
      <v-col>
        <v-sheet>
          <v-calendar
            ref="calendar"
            :value="value"
            :events="events"
            color="primary"
            type="week"
          >
            <template #day-body="{ date, week }">
              <div
                class="v-current-time"
                :class="{ first: date === week[0].date }"
                :style="{ top: nowY }"
              ></div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
  </v-app>
</div>
</template>
<style lang="css">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
}

.v-current-time.first::before {
  content: '';
  position: absolute;
  background-color: #ea4335;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-top: -5px;
  margin-left: -6.5px;
}
</style>

<script>
export default {
   data: () => ({
    value: '',
    ready: false,
    events: [
      {
        name: 'Weekly Meeting',
        start: '2020-10-14 09:00',
        end: '2020-10-14 10:00',
      },
      {
        name: 'Thomas\' Birthday',
        start: '2020-10-15',
      },
      {
        name: 'Mash Potatoes',
        start: '2020-10-16 12:30',
        end: '2020-10-16 15:30',
      },
    ],
  }),
  mounted () {
    this.ready = true;
    this.scrollToTime()
    this.updateTime()
  },
  computed: {
    cal () {
      return this.ready ? this.$refs.calendar : null
    },
    nowY () {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    },
  },
  methods: {
    getCurrentTime () {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime () {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)

      this.cal.scrollToTime(first)
    },
    updateTime () {
      setInterval(() => this.cal.updateTimes(), 60 * 1000)
    },
  }
}
</script>
